@import '../vars';

/*----------------------------------------------
                 Footer CSS
----------------------------------------------*/
#footer {
    position: relative;
    padding: 24px 0 12px;
}

.footer-content:nth-child(2){
    display: flex;
    justify-content: center;
}

.footer-contact-area li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
}

.footer-contact-area ul li:last-child {
    margin: 0;
}

.footer-contact-area ul li i {
    font-size: 30px;
    display: block;
    padding-right: 15px;
    color: var(--secondary-main-theme-color);
}

.footer-heading h5 {
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
    color: var(--main-theme-color);
}

.footer-heading h5:before {
    content: "";
    position: absolute;
    height: 2px;
    width: 40px;
    background: var(--secondary-main-theme-color);
    bottom: -10px;
}


.footer-list ul li {
    margin-bottom: 20px;
    color: var(--main-theme-color);
    font-family: var(--secondary-font-family);
}

.footer-list ul li:last-child {
    margin: 0;
}

.footer-list li a {
    color: var(--main-theme-color);
    font-family: var(--secondary-font-family);
}

.footer-list li a:hover {
    color: var(--secondary-heading-color);
}

.footer-bottom-img {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: -1;
    opacity: 0.05;
}

.copy-right {
    padding: 20px 0;
    background: white;
    -webkit-box-shadow: 0px 0px 26px -20px #000;
    box-shadow: 0px 0px 26px -20px #000;
}

.copy-icon ul li {
    display: inline-flex;
}

.copy-icon ul li a:hover {
    border: 2px solid transparent;
}

.copy-icon ul li a {
    height: 35px;
    width: 35px;
    text-align: center;
    line-height: 33px;
    border-radius: 50%;
    border: 2px solid var(--main-theme-color);
    font-size: 16px;
    margin-left: 10px;
    -webkit-transition: all 0.3s ease-in-out 0.1s;
    -o-transition: all 0.3s ease-in-out 0.1s;
    transition: all 0.3s ease-in-out 0.1s;
    color: var(--heading-color);
    display: block;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.copy-icon ul li a .icon {
    position: relative;
    color: var(--main-theme-color);
    transition: .5s;
    z-index: 3;
}

.copy-icon ul li a:hover .icon {
    color: #fff;
    transform: rotateY(360deg);
}

.copy-icon ul li a:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--secondary-main-theme-color);
    transition: .5s;
    z-index: 2;
}

.copy-icon ul li a:hover:before {
    top: 0;
}

// @media (max-width: 992px) {
//     .copy-right {
//         position: relative;
//         bottom: 0;
//         width: 100%;
//     }
// }