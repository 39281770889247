@import '../vars';

.star-rating {
    position: relative;
    display: inline-block;
    font-size: 1rem;
    color: #ffc107;
    width: 6rem;
    height: 1rem;
}

.star-rating .filled-stars,
.star-rating .empty-stars {
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}

.star-rating .filled-stars {
    color: #ffc107;
    z-index: 1;
    width: 0;
}

.star-rating .empty-stars {
    color: #d3d3d3;
}

.star-rating .empty-stars i {
    -webkit-text-stroke: 1px #ffc107;
    color: white;
}