@import '../vars';


/* --------------------------
       Job-Details Page
-----------------------------*/

.job-details-company-image {
    display: flex;
    justify-content: center;
}

.job-details-company-image img {
    max-width: 300px;
    max-height: 300px;
}

.job-main-details-paragraph h3 {
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 10px;
    color: var(--main-theme-color),
}

.btn-apply {
    background: var(--main-theme-color);
    color: var(--light-color);
    border: none;
}

.btn-apply:hover {
    background: var(--secondary-heading-color);
    color: var(--light-color);
}

.job-main-details {
    padding: 25px 25px;
}

.job-main-details hr {
    margin: 0;
    margin-top: 20px;
}

.apply-job-area {
    padding: 20px 0 0 0;
    text-align: center;
}

.apply-job-area ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.apply-job-area ul li {
    display: inline-block;
    padding-right: 10px;
    font-weight: 500;
}

.see-application p{
    text-decoration: underline;
    color: var(--main-theme-color) !important;
}

.see-application p:hover{
    color: var(--secondary-heading-color) !important;
}

.social-icon {
    background: #cccccc;
    height: 30px;
    width: 30px;
    display: inline-block;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    font-size: 14px;
    transition: .4s all ease;
    -webkit-transition: .4s all ease;
    -moz-transition: .4s all ease;
    -ms-transition: .4s all ease;
    -o-transition: .4s all ease;
}

.social-icon:hover {
    background: var(--main-theme-color);
    color: #fff;
}

.media .company-img img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    object-fit: cover;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.company-author {
    padding: 20px;
    margin-top: 30px;
}

.company-img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    margin-bottom: 8px;
}

.company-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.company-review {
    display: flex;
}

.company-review li {
    padding-right: 8px;
    color: var(--secondary-heading-color);
}

.company-name h4 {
    margin-bottom: 4px;
}

.company-name p {
    padding-top: 5px;
    color: var(--main-theme-color);
}

.post-share a {
    margin-right: 10px;
}

.company-description {
    padding-top: 10px;
}

.company-public ul li {
    padding-bottom: 20px;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 30px;
}

.company-public ul li:last-child {
    padding-bottom: 0;
    border-bottom: none;
}

.company-public .post-share a {
    font-size: 14px;
}

.company-public .post-share .replay {
    color: var(--main-theme-color);
}

.comment-area-post {
    margin-top: 30px;
    padding: 20px;
}

.comment-area-heading h3 {
    font-weight: 700;
    padding: 20px;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 30px;
}

.leave-form .form-control {
    border: 1px solid var(--main-theme-color);
}

.left-job-page {
    padding: 20px;
    margin-bottom: 30px;
}

.job-main-details-text {
    padding-top: 20px;
}

.job-main-details-author {
    border-top: 1px solid #777c8742;
    border-bottom: 1px solid #777c8742;
    padding: 5px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.job-author-view i {
    margin-right: 8px;
}

.job-author-view ul li {
    display: inline-block;
    padding-right: 30px;
    color: var(--paragraph-color);
    font-size: 14px;
}

.job-author-view ul li:nth-child(1) a {
    color: var(--main-theme-color);
}

.job-main-details-paragraph {
    padding-top: 20px;
}

.job-main-details-heading h2 {
    padding-bottom: 10px;
    color: var(--main-theme-color);
}

.right-side {
    padding-left: 40px;
}

.left-job {
    overflow: hidden;
    padding-bottom: 20px;
}

.left-job h4 {
    position: relative;
    color: var(--main-theme-color);
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 5px;
    padding: 15px 0px;
    text-transform: capitalize;
}

.left-job h4::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 50px;
    height: 2px;
    background: var(--main-theme-color);
}

.left-job li {
    display: flex;
    align-items: center;
}

.left-job ul li p {
    display: inline-block;
    padding: 7px 0px 7px 7px;
    text-transform: capitalize;
    position: relative;
}

.left-job ul li span {
    float: left;
    /* border: 1px solid var(--main-theme-color); */
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 50px;
    color: var(--secondary-main-theme-color);
    line-height: 24px;
}

.other-job {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    padding: 15px 0px;
}

.job-img {
    float: left;
    padding-right: 10px;
}

.other-job img {
    width: 100%;
    max-width: 80px;
    height: 80px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 2px;
}

.other-job-arrow {
    display: none;
}

.left-head-job .job-details-content p {
    margin-bottom: 5px;
}

.left-job {
    overflow: hidden;
    padding-bottom: 20px;
}

.text-info {
    color: var(--secondary-main-theme-color) !important;
}

.text-warning {
    color: var(--secondary-heading-color) !important;
}

.text-primary {
    color: var(--heading-color) !important;
}

.text-danger {
    color: var(--third-main-color) !important;
}

.text-success{
    color: var(--main-theme-color) !important;
}

@media (max-width: 992px) {
    .additional-info {
        margin-top: 30px;
    }

    .other-job-arrow {
        display: block;
    }
}