@import '../../vars';

.service-box {
    background: #fff;
    padding: 30px 30px;
    margin-bottom: 30px;
    position: relative;
    z-index: 99;
}

.service-box-inner img {
    -webkit-transition: all 0.5s ease-in;
    -o-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;
}

.service-box:hover .service-box-inner img {
    -ms-transform: rotateY(360deg);
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
    color: #fff;
}

.service-box-inner h3 a {
    color: var(--main-theme-color);
}

.service-box:hover .service-box-inner h3 a,
.service-box:hover {
    color: var(--secondary-heading-color);
}

.register-link {
    font-family: var(--primary-font-family);
    font-size: 22px;
    color: var(--heading-color);
}

.register-link:hover {
    color: var(--secondary-main-theme-color);
}

.service-box-inner p {
    padding-top: 10px;
}

.service-box-inner h6 {
    color: var(--third-contrast-color);
}