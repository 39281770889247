@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@font-face {
	font-family: 'wishshore';
	src: 
    url('../styles/fonts/wishshore.woff2') format('woff2'),
    url('../styles/fonts/wishshore.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

:root {
    // --main-theme-color: #5b6fe6;
    // --dark-bg-color: #f8f9ff;
    // --heading-color: #2c3552;
    // --paragraph-color: #777c87;
    // --banner-paragraph-color: #344a6f;
    --main-theme-color: #0a44a5;
    --heading-color: #ffb701;
    --third-main-color: #ff5746;
    --secondary-main-theme-color: #accfff;
    --secondary-heading-color: #ffd44f;
    --third-contrast-color: #fc8f81;
    --black-color: #454c53;
    --light-color: #f8f9ff;
    --paragraph-color: #777c87;
    --banner-paragraph-color: #344a6f;
    --primary-font-family: 'wishshore', sans-serif;
    --secondary-font-family: 'Montserrat', sans-serif;
}
