@import '../vars';

.navbar-container {
    background-color: transparent;
    padding: 15px 0px 15px 0px;
}

.navbar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu {
    display: none;
}

.logo {
    width: 175px;
}

.navbar-right {
    cursor: pointer;
}

.navbar-list ul,
.navbar-right ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

.navbar-list ul li a,
.navbar-right ul li a {
    text-decoration: none;
    font-family: var(--primary-font-family);
    color: var(--main-theme-color);
    font-weight: 600;
    border-radius: 8px;
    padding: 8px;
    font-size: 25px;
}

.navbar-list ul li a:hover {
    text-decoration: none;
    color: var(--secondary-heading-color);
}

.navbar-username {
    margin-right: 12px;
    font-weight: 300;
}

.navbar-img img {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: solid 2px var(--main-theme-color);
    object-fit: cover;
    margin-right: 4px;
}

.btn-login {
    color: #fff;
    background: var(--main-theme-color);
    -webkit-transition: all 0.3s ease-in-out 0.1s;
    -o-transition: all 0.3s ease-in-out 0.1s;
    transition: all 0.3s ease-in-out 0.1s;
}

.btn-login:hover {
    background-color: var(--secondary-heading-color);
    color: var(--light-color);
}

.btn-register {
    color: var(--heading-color);
    background: #fff;
    -webkit-transition: all 0.3s ease-in-out 0.1s;
    -o-transition: all 0.3s ease-in-out 0.1s;
    transition: all 0.3s ease-in-out 0.1s;
}

.btn-register:hover {
    background-color: var(--secondary-main-theme-color);
    color: var(--light-color);
}

// ---------- Dropdown -------------
.dropdown-menu-header {
    transform: translate3d(100%, 47px, 0px) !important;
}

.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 168px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
    background-color: var(--secondary-main-theme-color);
}

.dropdown-sign-out p {
    font-weight: bold;
    color: var(--third-main-color);
    margin-top: 4px;
}

.dropdown-title {
    font-weight: bold !important;
    color: var(--main-theme-color);
    border: none;
    background-color: transparent;
}

.dropdown-title:hover, .btn.show {
    background-color: transparent;
    color: var(--secondary-heading-color);
}

@media (max-width: 992px) {
    .navbar-list ul {
        display: none;
    }

    .menu {
        display: block;
        position: absolute;
        left: 15px;
    }

    .navbar-container {
        margin-left: 52px;
    }
}

@media (max-width: 768px) {
    .navbar-username {
        display: none;
    }

}

@media (max-width: 577px) {
    .navbar-list {
        display: none;
    }

    .navbar-img {
        display: none;
    }
    
    .navbar-right i {
        display: none;
    }

    .navbar-container div {
        justify-content: center;
    }

    .navbar-container {
        margin-left: 0px;
    }

    .navbar-buttons a {
        padding: 0 12px;
        font-size: 25px;
        line-height: 32px;
    }
}

@media (max-width: 500px) {
    .btn {
        padding: 0 10px;
    }

    .logo {
        width: 132px;
    }
}