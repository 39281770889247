@import '../vars';

/* --------------------------
    Filters lateral
-----------------------------*/

#fix-left {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}

#lateral-filter-container {
    background-color: white;
    position: fixed;
    top: 0;
    height: 0;
    height: 100vh;
    width: 80vw;
    display: flex;
    flex-direction: column;
    padding: 24px;
    overflow-y: auto;
    max-width: 440px;
}

#lateral-filter-header {
    margin-bottom: 4%;
    padding: 12px 0px;
}

.lateral-filter-go-out::before {
    position: absolute;
    right: 34px;
    top: 48px;
    cursor: pointer;
}