@media (max-width: 992px) {
    #banner-one {
        display: inherit;
        align-items: center;
        height: auto;
        padding-top: 150px;
        padding-bottom: 50px;
    }

    .tabs-heading {
        padding-top: 50px;
    }

    .tabs-about-button .nav-tabs .nav-link {
        font-size: 18px;
    }

    .about-tabs {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .animation-img {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .responsive-mt {
        margin-bottom: 30px;
    }

    .blog-search-option {
        margin-top: 20px;
    }

    .right-side {
        padding-left: 0px;
    }

    .responsive-mt-60 {
        margin-top: 60px;
    }

    .responsive-mt-32 {
        margin-top: 32px;
    }

    .copy-right {
        text-align: center;
    }

    .how-it-works-imgs {
        padding-top: 50px;
    }
}

@media (max-width: 768px) {

    #banner-one {
        display: inherit;
        align-items: center;
        height: auto;
        padding-top: 100px;
        padding-bottom: 50px;
    }

    .navbar-container {
        position: inherit;
    }

    .banner-img {
        text-align: center;
        margin-top: 30px;
    }

    .banner-img img {
        width: 50%;
    }

    .animation-img {
        text-align: center;
    }

    .animation-img img {
        width: 60%;
        padding-top: 60px;
    }

    .copy-icon {
        padding-top: 8px;
    }

    h1 {
        font-size: 55px;
    }

    .home-one-banner-content h2 {
        font-size: 35px;
        padding-top: 10px;
    }

    .home-one-banner-content p {
        padding-top: 10px;
    }

    .about-tabs {
        display: inherit;
        text-align: center;
    }

    .tabs-heading {
        padding-top: 50px;
    }

    .animation-img {
        display: inherit;
    }
}

@media (max-width: 576px) {
    .single-item-mt-3 {
        margin-top: 60px;
    }

    .social-login-area button {
        margin-bottom: 10px;
        width: 100%;
    }

    .social-login-area {
        display: inherit;
    }
}

@media (max-width: 480px) {
    .banner-one-btn {
        display: inherit;
        align-items: center;
    }

    .banner-img img {
        width: 90%;
    }

    .animation-img img {
        width: 80%;
    }
}

@media (max-width: 361px) {
    h1 {
        font-size: 45px;
    }

    #one-testimonial .testimonial-box {
        margin: 0 9px 32px 9px;
    }

    .customer-star {
        display: inherit;
    }

    .customer-star h6 {
        padding-top: 10px;
    }

    .media-header {
        display: inherit;
        justify-content: space-between;
    }
}