@import '../vars';

/*----------------------------------------------
            Back to top Button CSS
----------------------------------------------*/

.go-top-button {
    position: fixed;
    cursor: pointer;
    top: 0;
    right: 15px;
    color: #ffffff;
    background-color: var(--main-theme-color);
    z-index: 4;
    width: 40px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    opacity: 1;
    display: block;
    -webkit-transition: .9s;
    -o-transition: .9s;
    transition: .9s;
    border-radius: 50%;
    top: 98%;
    -webkit-transform: translateY(-98%);
    -ms-transform: translateY(-98%);
    transform: translateY(-98%);
    opacity: 1;
    visibility: visible;
}