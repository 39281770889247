@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");

/*================================================
                Default CSS
=================================================*/

body {
    height: 100vh;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: var(--secondary-font-family);
}

a {
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out 0.1s;
    -o-transition: all 0.3s ease-in-out 0.1s;
    transition: all 0.3s ease-in-out 0.1s;
    outline: 0 !important;
    color: var(---heading-color);
}

a:hover {
    text-decoration: none;
    color: var(--main-theme-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--primary-font-family);
}

h1 {
    font-size: 80px;
    font-weight: bold;
}

h2 {
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
}

h3 {
    font-size: 28px;
    font-weight: 500;
}

h4 {
    font-size: 25px;
    font-weight: 600;
    color: var(--main-theme-color);
}

h5 {
    font-size: 22px;
    font-weight: 400;
}

h6 {
    font-size: 18px;
    font-weight: 400;
}

p {
    font-size: 16px;
    line-height: 24px;
    color: var(--black-color);
    font-weight: 400;
    font-family: var(--secondary-font-family);
}

p:last-child {
    margin-bottom: 0;
}

ul {
    padding: 0;
    margin: 0;
}

ul li {
    list-style: none;
    padding: 0;
}

/* --Common Class Area start-- */

.box-shadow {
    -webkit-box-shadow: 0px 3px 18px 7px #5b6fe612;
    box-shadow: 0px 3px 18px 7px #5b6fe612;
    border-radius: 20px;
}

.border-radius {
    border-top-left-radius: 35px;
    border-bottom-right-radius: 35px;
}

.bg-color {
    background-color: var(--dark-bg-color) !important;
}

.section-title-center h3 {
    color: var(--main-theme-color);
}

.left-side-title h3 {
    color: var(--main-theme-color);
}

.m0 {
    margin: 0px;
}

.mt4 {
    margin-top: 4%;
}

.mb4 {
    margin-bottom: 4%;
}

.pt40 {
    padding-top: 40px;
}

.pt30 {
    padding-top: 30px;
}

.pt20 {
    padding-top: 20px;
}

.py100 {
    padding: 100px 0;
}

.py50 {
    padding: 50px 0;
}

.py40 {
    padding: 40px 0;
}

.py20 {
    padding: 20px 0;
}

.py10 {
    padding: 10px 0;
}

.pl20 {
    padding-left: 20px;
}

.pb24 {
    padding-bottom: 24px;
}

.pb12 {
    padding-bottom: 12px;
}


.align-center {
    display: flex;
    align-items: center;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.center-section {
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing-center-section {
    display: flex;
    justify-content: center;
    align-items: center;
}

/*================================================
                    Buttons CSS
=================================================*/

.btn {
    position: relative;
    display: inline-block;
    padding: 0 35px;
    font-size: 25px;
    line-height: 45px;
    font-weight: 400;
    font-family: var(--primary-font-family);
    -webkit-transition: all 0.3s ease-in-out 0.1s;
    -o-transition: all 0.3s ease-in-out 0.1s;
    transition: all 0.3s ease-in-out 0.1s;
    border-radius: 40px;
    margin: 0 5px;
}

.btn-theme {
    color: #fff;
    background: var(--heading-color);
    -webkit-transition: all 0.3s ease-in-out 0.1s;
    -o-transition: all 0.3s ease-in-out 0.1s;
    transition: all 0.3s ease-in-out 0.1s;
}

.btn-theme:hover {
    color: var(--light-color);
    background: var(--secondary-main-theme-color);
    -webkit-transition: all 0.3s ease-in-out 0.1s;
    -o-transition: all 0.3s ease-in-out 0.1s;
    transition: all 0.3s ease-in-out 0.1s;
}

/*================================================
                Loading component CSS
=================================================*/
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

/*================================================
                Loading component CSS
=================================================*/
.go-back {
    position: absolute;
    top: calc(90.67px + 1rem);
    left: 1rem;
    cursor: pointer;
    z-index: 998;
    font-size: 24px;
    color: var(--main-theme-color);
}

.go-back:hover {
    color: var(--third-main-color);
}

/* Ajustes de altura del header para pantallas pequeñas */
@media (max-width: 500px) {
    .go-back {
      top: calc(68.93px + 1rem);
    }
  }
  

/* Breakpoints ajustados a los tamaños del contenedor Bootstrap */
@media (min-width: 576px) {
    .go-back {
        left: calc((100% - 540px) / 2 + 1rem);
    }
}

@media (min-width: 768px) {
    .go-back {
        left: calc((100% - 720px) / 2 + 1rem);
    }
}

@media (min-width: 992px) {
    .go-back {
        left: calc((100% - 960px) / 2 + 1rem);
    }
}

@media (min-width: 1200px) {
    .go-back {
        left: calc((100% - 1140px) / 2 + 1rem);
    }
}

@media (min-width: 1400px) {
    .go-back {
        left: calc((100% - 1320px) / 2 + 1rem);
    }
}