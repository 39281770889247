@import '../vars';

.react-datepicker__header__dropdown {
    margin-top: 4px;
}

@media (max-width: 600px) {
    .modal-buttons {
        padding: 0px 20px !important;
        font-size: 20px !important;
        line-height: 36px !important;
    }
}