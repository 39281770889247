@import '../../vars';

.nav-title h2 {
    color: var(--heading-color);
}

.nav-link {
    color: var(--secondary-main-theme-color);
    font-family: var(--primary-font-family);
    font-size: 25px;
}

.navs-title .nav-link.active {
    color: var(--main-theme-color);
}

.navs-title {
    border-bottom: 1px solid #dee2e6;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: flex-start;
}

.navs-title button {
    font-size: 28px !important;
    font-weight: 500 !important;
}

.nav-item {
    background-color: transparent;
    border-color: transparent;
    border-bottom: 2px solid var(--main-theme-color);
    margin: 0 25px 0 0;
}

.list-content {
    display: flex;
}

.list-content img {
    width: 45px;
    height: 45px;
    margin: auto;
}

.list-content p {
    margin-left: 16px;
}