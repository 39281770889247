.privacy-policy-container {
  padding: 3rem 0;
}

.privacy-policy-container h2 {
  color: var(--heading-color);
  text-align: center;
  margin-bottom: 40px;
}

.privacy-policy-container h3 {
  color: var(--main-theme-color);
  margin: 20px 0;
}

.privacy-policy-container h4 {
  color: var(--secondary-main-theme-color);
  font-weight: 400;
}

.privacy-policy-container p {
  color: var(--black-color);
  margin: 10px 0;
}

.privacy-policy-container p a {
  color: var(--main-theme-color);
}

.privacy-policy-container p a:hover {
  color: var(--secondary-heading-color);
}

@media (max-width: 600px) {
  .privacy-policy-container {
    margin-left: 30px;
    margin-right: 30px;
  }
}
