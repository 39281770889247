@import '../vars';

.contact-us-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: space-evenly;
}

.contact-us {
    padding: 80px 10px;
}

.contact-us h2 {
    color: var(--main-theme-color);
}